@font-face {
	font-family: Roobert;
	font-display: swap;
	font-weight: 400;
	src:
		local('Roobert-Regular'),
		url('../assets/fonts/Roobert/Roobert-Regular.woff2') format('woff2'),
		url('../assets/fonts/Roobert/Roobert-Regular.woff') format('woff'),
		url('../assets/fonts/Roobert/Roobert-Regular.ttf') format('truetype'),
		url('../assets/fonts/Roobert/Roobert-Regular.otf') format('opentype');
}

@font-face {
	font-family: Roobert;
	font-display: swap;
	font-weight: 500;
	src:
		local('Roobert-Medium'),
		url('../assets/fonts/Roobert/Roobert-Medium.woff2') format('woff2'),
		url('../assets/fonts/Roobert/Roobert-Medium.woff') format('woff'),
		url('../assets/fonts/Roobert/Roobert-Medium.ttf') format('truetype'),
		url('../assets/fonts/Roobert/Roobert-Medium.otf') format('opentype');
}

@font-face {
	font-family: Roobert;
	font-display: swap;
	font-weight: 600;
	src:
		local('Roobert-SemiBold'),
		url('../assets/fonts/Roobert/Roobert-SemiBold.woff2') format('woff2'),
		url('../assets/fonts/Roobert/Roobert-SemiBold.woff') format('woff'),
		url('../assets/fonts/Roobert/Roobert-SemiBold.ttf') format('truetype'),
		url('../assets/fonts/Roobert/Roobert-SemiBold.otf') format('opentype');
}
