@import 'mixins';

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
	background-color: #fff;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
	background-color: $cramo-green;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
div.mat-slide-toggle-thumb {
	box-shadow:
		-1px 1px 2px 1px rgb(0 0 0 / 20%),
		0 1px 1px 0 rgb(0 0 0 / 14%),
		0 1px 3px 0 rgb(0 0 0 / 12%);
}

// override tooltip
.ng-tooltip {
	max-width: 120px;
	background-color: $cramo-orange;
	color: #fff;
	text-align: center;
	border-radius: 2px;
	padding: 5px;
	position: absolute;
	z-index: 1000;
	display: block;
	opacity: 0;
	transition: opacity 300ms;
}

.ng-tooltip-show {
	opacity: 1;
}

.ng-tooltip::after {
	content: '';
	position: absolute;
	border-style: solid;
}

.ng-tooltip-top::after {
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-color: $cramo-orange transparent transparent transparent;
}

.ng-tooltip-bottom::after {
	bottom: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-color: transparent transparent $cramo-orange;
}

.ng-tooltip-left::after {
	top: 50%;
	left: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-color: transparent transparent transparent $cramo-orange;
}

.ng-tooltip-right::after {
	top: 50%;
	right: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-color: transparent $cramo-orange transparent transparent;
}

.mat-expansion-panel-body div {
	min-height: 0 !important; /* Set to 0 or any other value as needed */
}

.mat-badge-content {
	font-size: 11px !important;
	font-weight: 600 !important;
}

/* To imitate how mat-error looks inside mat-form-field */
mat-error {
	@include font-regular(12px);

	letter-spacing: 0.0333em;
	-webkit-font-smoothing: antialiased;
	animation: fade-in-error 0.5s forwards;

	@keyframes fade-in-error {
		from {
			opacity: 0;
			transform: translateY(-30%);
		}

		to {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

// Removes the check to the right in a mat-select
mat-pseudo-checkbox {
	display: none !important;
}
