@import 'variables';
@import 'mixins';

.cramo-link {
	@include font-medium;

	color: $cramo-red;
	text-decoration: none;
	background-color: transparent;
	cursor: pointer;

	&:hover {
		color: adjust($cramo-red, -7.5%);
		text-decoration: underline !important;
	}
}
