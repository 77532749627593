/* fdirection-main-cross */

.fflex {
	flex: 1;
}

.ffill {
	display: flex;
	height: 100%;
	width: 100%;
}

.fwrap {
	display: flex;
	flex-flow: row wrap;
}

.frow-start-start {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

.frow-start-center {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.frow-start-stretch {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
}

.frow-center-center {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.frow-center-stretch {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
}

.frow-between-center {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.frow-between-start {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.frow-between-stretch {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
}

.frow-between-end {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
}

.frow-end-center {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.frow-end-start {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
}

.frow-end-stretch {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: stretch;
}

.frow-around-stretch {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: stretch;
}

.frow-reverse-start-stretch {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-start;
	align-items: stretch;
}

.fcolumn-start-center {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.fcolumn-start-start {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.fcolumn-start-end {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
}

.fcolumn-center-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.fcolumn-between-center {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.fcolumn-between-stretch {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
}

.fcolumn-center-start {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.fcolumn-center-end {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.fcolumn-center-stretch {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
}

.fcolumn-start-stretch {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
}
