$cramo-red: #da010b; // Primary color
$cramo-dark-blue: #19314f; // Used as call to action color
$cramo-dark-red: #b2010a;
$cramo-medium-red: #f5b8ba;
$cramo-light-red: #fbdede;
$cramo-black: #000;
$cramo-white: #fff;
$cramo-grey: #d1d3d4;
$cramo-medium-grey: #ebebeb;
$cramo-background: #f5f5f5;
$cramo-primary: $cramo-dark-blue;
$cramo-primary-10: #e3e9f0;
$cramo-primary-20: #c1d4eb;
$cramo-smartcontrol-button-color: #2d578d;
$cramo-shadow-color: rgb(0 0 0 / 35%);
$cramo-field-border-color: #9e9e9e;

/* stylelint-disable-next-line scss/dollar-variable-empty-line-before */
$cramo-orange: #d77d4b;
$cramo-light-grey: #f7f7f7;
$cramo-light-grey-transparent: rgb(239 240 241 / 95%);
$cramo-dark-grey-transparent: rgb(0 0 0 / 55%);
$cramo-dark-grey: #8b8b8b;
$cramo-blue: #496074;
$cramo-green: #507d55;
$cramo-lightest-blue: #b1d1da;
$cramo-light-blue: #aad2dc;
$cramo-light-black: #444;
$cramo-blue-30: #11223b;
$cramo-user-nav: $cramo-dark-blue;
$cramo-info: $cramo-dark-blue;
$cramo-info-10: #e8eaed;
$cramo-warning-10: #fcf5f2;
$cramo-success: #507d55;
$cramo-success-10: #e8eee9;
$cramo-error: #cd2200;
$cramo-warning: #cd2200;
$cramo-error-10: #f8e8e5;
$cramo-grey-5: #f4f4f4;
$cramo-grey-10: #ebeced;
$cramo-grey-15: #ccc;
$cramo-grey-20: #b7babb;
$cramo-grey-30: #9ca1a3;
$cramo-grey-40: #82878a;
$cramo-grey-50: #696e70;
$cramo-grey-60: #505456;
$cramo-grey-70: #383a3c;
$cramo-grey-80: #1f2121;
$cramo-link: #212529;
$cramo-border: $cramo-grey-15;
$xxs: 'only screen and (max-width: 420px)';
$xs: 'only screen and (max-width: 599px)';
$sm: 'only screen and (min-width: 600px) and (max-width: 959px)';
$md: 'only screen and (min-width: 960px) and (max-width: 1279px)';
$lg: 'only screen and (min-width: 1280px) and (max-width: 1919px)';
$xl: 'only screen and (min-width: 1920px) and (max-width: 5000px)';
$gt-xs: 'only screen and (min-width: 600px)';
$gt-sm: 'only screen and (min-width: 960px)';
$gt-md: 'only screen and (min-width: 1280px)';
$gt-lg: 'only screen and (min-width: 1920px)';
$lt-sm: 'only screen and (max-width: 599px)';
$lt-md: 'only screen and (max-width: 959px)';
$lt-lg: 'only screen and (max-width: 1279px)';
$lt-xl: 'only screen and (max-width: 1919px)';
$header-area-height: 130px;
$header-area-height-mobile: 96px;
$font-family: 'Roobert', 'Arial', sans-serif;
$spacing-base: 6px;
$spacing-base-x2: 12px;
$spacing-base-x3: 18px;
$spacing-base-x4: 24px;
$spacing-base-x5: 30px;
$spacing-base-x6: 36px;
$spacing-base-x7: 42px;
$spacing-base-x8: 48px;
$spacing-base-x9: 54px;
$spacing-base-x10: 60px;
$image-border-radius: 2px;
$border-radius-default: 4px;
