@import 'bootstrap/scss/bootstrap';
@import 'customize-bootstrap';
@import 'variables';
@import 'block-style';
@import 'material';
@import 'layout';
@import 'link';
@import 'buttons';
@import 'skeleton-loading';
@import 'fonts';
@import 'mixins';

html {
	height: 100%;
}

body {
	margin: 0;

	@include font-regular;
}

h1 {
	@include font-semi-bold(40px);

	@media #{$lt-sm} {
		@include font-semi-bold(24px);
	}
}

h2 {
	@include font-semi-bold(40px);

	@media #{$lt-sm} {
		@include font-semi-bold(30px);
	}
}

h3 {
	@include font-semi-bold(30px);

	@media #{$lt-sm} {
		@include font-semi-bold(20px);
	}
}

h4 {
	@include font-semi-bold(20px);

	@media #{$lt-sm} {
		@include font-semi-bold(16px);
	}
}

h1,
h2,
h3,
h4,
strong {
	@include font-semi-bold;

	margin-top: 0;
	line-height: 96%;
}

// This CSS code ensures that special characters such as Å at the top of headings
// (h1 to h5) are not cut off by setting an appropriate line-height of 124%.
.overflow-hidden {
	h1,
	h2,
	h3,
	h4,
	h5 {
		line-height: 124%;
	}
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
	&.dark-backdrop {
		background: #000;
		opacity: 0.85 !important;
	}
}

.cdk-overlay-pane {
	min-width: 130px !important;

	@media #{$xs} {
		max-width: calc(100vw - 20px) !important;
		min-width: auto !important;
		width: inherit;
	}
}

.cdk-overlay-container {
	z-index: 1001 !important;
}

.cdk-global-overlay-wrapper .mat-mdc-dialog-container {
	height: auto;
}

.order-return-modal {
	.cramo-modal {
		&__header {
			padding: 0.25rem 0;
		}
	}
}

router-outlet ~ * {
	height: 100%;
	width: 100%;

	@media print {
		position: relative;
	}
}

@media print {
	@page {
		margin: 50px 0;
	}

	.no-print {
		display: none;
	}

	cramo-header {
		display: none;
	}

	cramo-footer {
		display: none;
	}
}

.position-relative {
	position: relative;
}

p {
	@include font-regular(16px);

	margin-top: 0;
}

body,
html {
	@include font-regular;

	padding: 0;
	margin: 0;
	min-height: 100vh;
}

body.modal-open {
	overflow: auto;
}

.cursor {
	cursor: pointer;
}

.no-outline {
	outline: none;
}

.page-wrapper {
	max-width: 1280px;
	width: 100%;
	margin: 0 auto;
}

.user-page-wrapper {
	max-width: 1280px;
	width: 100%;
	margin: 0 auto;

	@include media-breakpoint-up(md) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.ie11-page-wrapper {
	max-width: 1280px;
	width: 100%;
}

.page-padding {
	padding: 0 10px;
}

.spacer {
	flex-grow: 1;
}

.mandatory {
	color: $cramo-red;
}

.margin-top-20 {
	margin-top: 20px;
}

.page-margin {
	margin: 0 10px;
}

.panel {
	background-color: white;
}

.block-padding {
	@media screen and (width <=599px) {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	@media screen and (width >=600px) {
		padding-top: 20px;
		padding-bottom: 40px;
	}
}

.link-btn {
	cursor: pointer;
}

.cramo-btn-link {
	color: $cramo-red;
	background: transparent;

	&:hover,
	&:focus {
		color: adjust($cramo-red, -10);
		text-decoration: underline;
	}
}

.cramo-btn-link-2 {
	color: $cramo-dark-blue;
	background: transparent;

	&:hover,
	&:focus {
		color: adjust($cramo-dark-blue, -10);
		text-decoration: underline;
	}
}

.long-body-text,
.single-line-text {
	@include font-regular(16px);
}

.specification {
	@include font-regular(15px);
}

.standard-menu-link {
	@include font-regular;
}

.btn-cta {
	@include font-semi-bold(15px);
}

// user agent styles will overwrite this if it is put in component or cta-styles
.cta-content-area {
	h1,
	h2,
	h3,
	h4 {
		margin-bottom: 20px;
		line-height: 95%;
		-webkit-margin-before: 0;
	}
}

.noselect {
	user-select: none;
}

a {
	color: inherit;

	&:hover {
		color: inherit;
	}
}

.show-more-results {
	padding: 5px 10px;
	color: $cramo-red;
	cursor: pointer;
	margin: 20px 0;

	@include font-semi-bold(16px);
}

.break-word {
	word-wrap: break-word;
	overflow-wrap: break-word;
	word-break: break-word;
}

.ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.blink-me {
	animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 0.2;
	}
}

.block-content,
.cms-richtext,
.rtf-content {
	a {
		@include font-semi-bold;

		color: $cramo-red;
	}
}

.cms-richtext {
	a:hover,
	a:focus {
		text-decoration: underline !important;
	}
}

.cramo-cancel-btn {
	min-height: 40px;
	background-color: white;
	color: $cramo-dark-grey;
	border: 1px solid $cramo-dark-grey;
	border-radius: 0;
	padding: 5px 10px;
	cursor: pointer;

	@include font-semi-bold(16px);
}

.cramo-close {
	color: $cramo-red;
	font-size: 24px;
}

.mat-mdc-dialog-title {
	@include font-semi-bold;
}

.container-shadow {
	border-radius: 2px;
	box-shadow:
		0 1px 3px 0 rgb(0 0 0 / 20%),
		0 1px 1px 0 rgb(0 0 0 / 14%),
		0 3px 2px -1px rgb(0 0 0 / 12%);
}

.confirm-jobsite {
	&__container {
		@include font-regular(16px);

		padding: 20px 15px;
		color: black;

		@media (width <=340px) {
			@include font-regular(15px);
		}
	}

	&__header {
		@include font-semi-bold;

		margin-bottom: 10px;
	}

	&__checkbox-area {
		margin-bottom: 15px;
	}

	&__btn {
		@include font-semi-bold;

		background-color: $cramo-red;
		padding: 5px 20px;
		color: white;
		cursor: pointer;
		border: 2px solid $cramo-grey;
		min-height: 50px;
		text-decoration: capitalize;
	}
}

.account-box {
	background-color: #fff;
	box-shadow:
		0 1px 3px 0 rgb(0 0 0 / 20%),
		0 1px 1px 0 rgb(0 0 0 / 14%),
		0 3px 2px -1px rgb(0 0 0 / 12%);
	border-radius: 2px;
}

.account-section {
	margin-top: 2rem;

	&__title {
		font-size: 20px !important;
	}

	&__desc {
		color: #666;
		margin: 0;
	}

	&__header {
		padding: 1.2rem;
		padding-bottom: 0;

		@include media-breakpoint-up(md) {
			padding: 2rem;
			padding-bottom: 0;
		}
	}

	&__content {
		padding-left: 1.2rem;
		padding-right: 1.2rem;

		@include media-breakpoint-up(md) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}

	&__footer {
		border-top: 1px solid #ddd;
	}

	&__link {
		padding: 1rem 1.2rem;
		display: block;
		color: $cramo-red;

		@include media-breakpoint-up(md) {
			padding: 1rem 2rem;
		}

		&:hover,
		&:focus {
			background-color: #f1f1f1;
		}
	}
}

.maxw-840 {
	max-width: 840px;
}

.cookie-modal-open {
	overflow: hidden;
}

.soft-break-before-link {
	p {
		a::before {
			content: ' ' !important;
		}
	}
}

.alert-message {
	p:last-of-type {
		margin-bottom: 0 !important;
	}
}

.btn-grey-borderless {
	@include font-semi-bold(14px);

	padding: 10px 15px;
	cursor: pointer;
	color: $cramo-grey-50;
	text-align: right;
	display: inline-block;
	margin: 0.25rem 0 0;
	background-color: transparent;
	border: 0;

	&:focus,
	&:hover {
		background-color: #f6f6f6;
		color: $cramo-dark-blue;
	}
}

.form-card {
	border: 1px solid $cramo-grey;
	padding: 1.5rem;
	margin: 0 0 1.5rem;
	position: relative;
}

.account-application-edit-button {
	position: absolute;
	right: 0;
	top: 0;
	padding: 0.75rem;
	font-size: 12px;
}

label {
	margin-bottom: 0;
}

.map-marker-label {
	margin-left: 10px;
	margin-top: 35px;
	background-color: $cramo-orange;
	padding: 5px 10px 7px;
	color: white !important;
	font-family: Roobert, Arial, sans-serif !important;
	font-weight: 400 !important;
	white-space: nowrap;
	border-radius: 2px;

	&.is-web-depot {
		background-color: $cramo-red;
	}

	&.is-default-depot {
		background-color: green;
		position: relative;
	}
}

/* stylelint-disable-next-line selector-class-pattern */
.mdc-tooltip__surface {
	background-color: $cramo-grey-70 !important;
	font-family: Roobert, Arial, sans-serif !important;
}

.mdc-label {
	font-family: Roobert, Arial, sans-serif !important;
}

.font-semi-bold {
	@include font-semi-bold;
}

.divider {
	border-bottom: 1px solid $cramo-grey;
}
