@use '@angular/material' as mat;
@import 'variables';

@mixin text-link {
	color: $cramo-link;

	&:hover,
	&:focus {
		color: $cramo-link;
	}
}

@mixin font-regular($font-size: null) {
	font-family: $font-family;
	font-weight: 400;

	@if $font-size {
		font-size: $font-size;
	}
}

@mixin font-medium($font-size: null) {
	font-family: $font-family;
	font-weight: 500;

	@if $font-size {
		font-size: $font-size;
	}
}

@mixin font-semi-bold($font-size: null) {
	font-family: $font-family;
	font-weight: 600;

	@if $font-size {
		font-size: $font-size;
	}
}

@mixin skeleton-loading($width: auto, $height: auto) {
	background-color: $cramo-grey-10;
	border-radius: $border-radius-default;
	animation: loading 2s infinite;
	width: $width;
	height: $height;

	@keyframes loading {
		0%,
		90% {
			opacity: 1;
		}

		45% {
			opacity: 0.5;
		}
	}
}

$sm-button-typography: mat.m2-define-typography-config(
	$font-family: $font-family,
	$button:
		mat.m2-define-typography-level(
			$font-weight: 600,
			$font-size: 14px,
			$line-height: 1,
			$letter-spacing: normal,
		),
);
$sm-button-theme: mat.m2-define-light-theme(
	(
		typography: $sm-button-typography,
		density: -1,
	)
);

@mixin small-button {
	@include mat.button-density($sm-button-theme);
	@include mat.button-typography($sm-button-theme);
}

@mixin hide-scroll-bar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	&::-webkit-scrollbar {
		display: none; /* Chrome */
	}
}
