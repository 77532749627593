$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	smbig: 600px,
	// new
	md: 768px,
	mdbig: 960px,
	// new
	lg: 992px,
	xl: 1200px,
	xlbig: 1280px,
	// new
	xxl: 1920px,
) !default;
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xlbig: 1280px,
) !default;

@media (width >= 1140px) {
	.container {
		max-width: 1280px;
	}
}

.form-control:focus {
	box-shadow: 0 0 0 0.1rem rgb(0 123 255 / 25%);
}

.form-control:invalid,
.form-control.is-invalid {
	background-image: inherit;
	padding-right: 0.75rem;
}

.form-control:invalid:focus,
.form-control.is-invalid:focus {
	box-shadow: 0 0 0 0.1rem rgb(220 53 69 / 25%);
}
