@import 'variables';
@import 'mixins';

$disabled-opacity: 0.65;

.cramo-btn {
	@include font-medium(16px);

	line-height: 1.5;
	padding: 8px 1.6rem;
	border-radius: 2px;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	border: 1px solid transparent;
	text-decoration: none;
	transition:
		color 0.15s ease-in-out,
		background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;

	&:not(:disabled, .disabled) {
		cursor: pointer;
	}

	&-primary {
		color: white;
		background-color: $cramo-dark-blue;

		&:focus,
		&:hover {
			color: white;
			background-color: adjust($cramo-dark-blue, -7.5%);
			border-color: adjust($cramo-dark-blue, -10%);
		}

		&:disabled,
		&.disabled {
			pointer-events: none;
			opacity: $disabled-opacity;
		}
	}

	&-secondary {
		color: white;
		background-color: $cramo-red;

		&:focus,
		&:hover {
			color: white;
			background-color: adjust($cramo-red, -7.5%);
			border-color: adjust($cramo-red, -10%);
		}

		&:disabled,
		&.disabled {
			pointer-events: none;
			opacity: $disabled-opacity;
		}
	}

	&-success {
		color: white;
		background-color: $cramo-green;

		&:focus,
		&:hover {
			color: white;
			background-color: adjust($cramo-green, -7.5%);
			border-color: adjust($cramo-green, -10%);
		}

		&:disabled,
		&.disabled {
			pointer-events: none;
			opacity: $disabled-opacity;
		}
	}

	&-link {
		background-color: transparent;

		&:focus,
		&:hover {
			text-decoration: underline !important;
		}

		&:disabled,
		&.disabled {
			pointer-events: none;
			opacity: $disabled-opacity;
		}
	}

	&-outline {
		&-primary {
			background-color: transparent;
			border: 1px solid $cramo-dark-blue;
			color: $cramo-dark-blue;

			&:focus,
			&:hover {
				color: white;
				background-color: $cramo-dark-blue;
			}

			&:disabled,
			&.disabled {
				pointer-events: none;
				opacity: $disabled-opacity;
			}
		}

		&-secondary {
			background-color: transparent;
			border: 1px solid $cramo-red;
			color: $cramo-red;

			&:focus,
			&:hover {
				color: white;
				background-color: $cramo-red;
			}

			&:disabled,
			&.disabled {
				pointer-events: none;
				opacity: $disabled-opacity;
			}
		}

		&-dark {
			background-color: transparent;
			border: 1px solid $cramo-light-black;
			color: $cramo-light-black;

			&:focus,
			&:hover {
				color: white;
				background-color: $cramo-light-black;
			}

			&:disabled,
			&.disabled {
				pointer-events: none;
				opacity: $disabled-opacity;
			}
		}

		&-grey {
			background-color: transparent;
			border: 1px solid $cramo-dark-grey;
			color: $cramo-light-black;

			&:focus,
			&:hover {
				color: white;
				background-color: $cramo-dark-grey;
			}

			&:disabled,
			&.disabled {
				pointer-events: none;
				opacity: $disabled-opacity;
			}
		}
	}

	&-block {
		width: 100%;
		display: block;
	}

	&-sm {
		padding: 4px 1.3rem;
		font-size: 15px;
	}

	&-xs {
		padding: 0.25rem 0.5rem;
		border-radius: 0.25rem;
		font-size: 13px;
		border-width: 1px;
	}
}
